import {
  GET_CAMPAIGN_PERF_KPI,
  GET_CAMPAIGN_PERF_KPI_FAILURE,
  GET_CAMPAIGN_PERF_KPI_SUCCESS,
  GET_LAST_SYNC_DATE,
  GET_LAST_SYNC_DATE_FAILURE,
  GET_LAST_SYNC_DATE_SUCCESS,
  LOAD_CAMPAIGN_KPIS,
  LOAD_CAMPAIGN_KPIS_FAILURE,
  LOAD_CAMPAIGN_KPIS_SUCCESS,
  REFRESH_KPIS,
  REFRESH_KPIS_FAILURE,
  REFRESH_KPIS_SUCCESS,
  REFRESH_KPIS_TASK_CREATED,
  REFRESH_KPIS_TASK_UPDATED
} from './types';


const initialState = {
  loading: false,
  // connectionKpis is a Map with key = "sourceId"_"targetId" and value if KPIs values. 
  // to get KPIs values for the link between step1 and step2, just retrieve `connectionsKpi['step1_step2']`
  connectionsKpis: {},
  // stepsKpi is a Map with key = "stepId" and value if KPIs values. 
  // to get KPIs values for the step step1, just retrieve `stepsKpis['step1']`
  stepsKpis: {},
  refreshing: false,
  lastSyncDateLoading: false,
  lastSyncDateError: false,
  lastSyncDate: null,
  refreshTask: null,
  performanceKpi: null,
  performanceKpiLoading: false,
};

const buildConnectionsMap = (connections) => Object.fromEntries(
  connections.map(
    ({ source, target, value }) => [`${source}_${target}`, value]
  )
);

const buildStepsMap = (steps) => Object.fromEntries(
  steps.map(
    ({ id, firstActivity, lastActivity, startDate, endDate, name, subject, progress, pctReached, details }) => [
      id,
      { name, subject, firstActivity, lastActivity, startDate, endDate, progress, pctReached, details }
    ]
  )
);

export function campaignKpisReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_CAMPAIGN_KPIS:
      return { ...state, loading: true };
    case LOAD_CAMPAIGN_KPIS_SUCCESS:
      const { connections, steps } = action.payload;
      return { ...state,
        loading: false,
        connectionsKpis: buildConnectionsMap(connections),
        stepsKpis: buildStepsMap(steps),
      };
    case LOAD_CAMPAIGN_KPIS_FAILURE:
      return { ...state, loading: false };
    case REFRESH_KPIS:
      return { ...state, refreshing: true };
    case REFRESH_KPIS_TASK_CREATED:
    case REFRESH_KPIS_TASK_UPDATED:
      return { ...state, refreshTask: action.payload };
    case REFRESH_KPIS_SUCCESS:
    case REFRESH_KPIS_FAILURE: 
      return { ...state, refreshing: false };
    case GET_LAST_SYNC_DATE: 
      return { ...state, lastSyncDateLoading: true };
    case GET_LAST_SYNC_DATE_SUCCESS: 
      return { ...state, lastSyncDateLoading: false, lastSyncDateError: false, lastSyncDate: action.payload };
    case GET_LAST_SYNC_DATE_FAILURE:
      return { ...state, lastSyncDateLoading: false, lastSyncDateError: true }
    case GET_CAMPAIGN_PERF_KPI: 
      return { ...state, performanceKpiLoading: true };
    case GET_CAMPAIGN_PERF_KPI_SUCCESS: 
      return { ...state, performanceKpiLoading: false, performanceKpi: action.payload };
    case GET_CAMPAIGN_PERF_KPI_FAILURE: 
      return { ...state, performanceKpiLoading: false, performanceKpi: null };
    default:
      return state;
  }
}
