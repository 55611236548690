import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { AuthenticatedApp } from './app/AuthenticatedApp';
import './i18n';
import '@xyflow/react/dist/style.css';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { Box, CircularProgress } from '@mui/material';

const Fallback = () => {
  return (
    <Box display="flex" justifyContent="center" sx={{ pt: 16 }}>
      <CircularProgress />
    </Box>
  )
}

const persistor = persistStore(store);

ReactDOM.render(
  <Suspense fallback={Fallback}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <AuthenticatedApp />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </Suspense>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
