import axios from 'axios';

import { switchBasePath, fragments } from '../../app/constants/api-constants';
import {
  LOAD_TAGS_LIST_STARTED,
  LOAD_TAGS_LIST_FINISHED,
  TEMPLATE_PREVIEW_STARTED,
  TEMPLATE_PREVIEW_FINISHED,
  SAVE_CONTENT_STARTED,
  SAVE_CONTENT_FINISHED,
  SAVE_ATTACHMENTS_STARTED,
  SAVE_ATTACHMENTS_FINISHED,
  LOAD_CONTENT_STARTED,
  LOAD_CONTENT_FINISHED,
  SET_CONTENT_APPROVAL_STATUS_STARTED,
  SET_CONTENT_APPROVAL_STATUS_FINISHED,
  SET_CONTENT_FRAGMENT_STATUS_STARTED,
  SET_CONTENT_FRAGMENT_STATUS_FINISHED,
  INIT_NEW_ANNOTATION,
  SAVE_NEW_ANNOTATION_STARTED,
  SAVE_NEW_ANNOTATION_FINISHED,
  CANCEL_NEW_ANNOTATION,
  UPDATE_ANNOTATION_STARTED,
  UPDATE_ANNOTATION_FINISHED,
  LOAD_ANNOTATIONS_TAGS_LIST_STARTED,
  LOAD_ANNOTATIONS_TAGS_LIST_FINISHED,
  LOAD_ANNOTATIONS_LIST_STARTED,
  LOAD_ANNOTATIONS_LIST_FINISHED,
  LOAD_BRIEF_REQUEST_COMMENTS_STARTED,
  LOAD_BRIEF_REQUEST_COMMENTS_FINISHED,
  DELETE_ANNOTATION_STARTED,
  DELETE_ANNOTATION_FINISHED,
  DELETE_CONTENT_STARTED,
  DELETE_CONTENT_FINISHED,
  CREATE_FRAGMENT_STARTED,
  CREATE_FRAGMENT_FINISHED,
  CANCEL_EMAIL_STARTED,
  CANCEL_EMAIL_FINISHED,
  ADD_BRIEF_REQUEST_COMMENT_FINISHED,
  ADD_BRIEF_REQUEST_COMMENT_STARTED,
  LOAD_BRIEF_REQUEST_STARTED,
  LOAD_BRIEF_REQUEST_FINISHED,
  HANDLE_UPDATED_BRIEF_REQUEST,
  ADD_FRAGMENT_ADDITIONAL_CONTACT_STARTED,
  ADD_FRAGMENT_ADDITIONAL_CONTACT_FINISHED,
  REMOVE_FRAGMENT_ADDITIONAL_CONTACT_STARTED,
  REMOVE_FRAGMENT_ADDITIONAL_CONTACT_FINISHED,
  SAVE_USER_TEMPLATE_STARTED,
  SAVE_USER_TEMPLATE_FINISHED,
  LOAD_BEST_PRACTICES_STARTED,
  LOAD_BEST_PRACTICES_FINISHED,
  LOAD_DELIVERABILITY_STARTED,
  LOAD_DELIVERABILITY_FINISHED,
  LOAD_WORD_RESTRICTIONS_STARTED,
  LOAD_WORD_RESTRICTIONS_FINISHED,
} from './types';

export const previewTemplate = (templateId, templateVersion, data, displayId) => async dispatch => {
  let renderResult = {
    error: null,
    content: null,
  };
  try {
    dispatch({ type: TEMPLATE_PREVIEW_STARTED });
    const response = await axios.post(
      `${switchBasePath}/templates/${templateId}/preview?version=${templateVersion}${displayId ? `&displayId=${displayId}` : '' // displayId is not defined for website
      }
      `,
      data,
    );
    renderResult = {
      preview: {
        error: null,
        content: response.data.content,
      },
      context: response.data.context,
    };
  } catch (error) {
    const errorMessage = error?.response?.data?.message || 'Unknown error occured';
    renderResult = {
      preview: {
        content: null,
        error: errorMessage,
      },
      context: null,
    };
  }
  dispatch({
    type: TEMPLATE_PREVIEW_FINISHED,
    payload: renderResult,
  });
};

export const getEmailContent = (type, stepDisplayId) => async dispatch => {
  dispatch({ type: LOAD_CONTENT_STARTED });
  try {
    const { data } = await axios.get(`${switchBasePath}/content/email/${type}/${stepDisplayId}`);
    dispatch({ type: LOAD_CONTENT_FINISHED, payload: data });
    dispatch(computeDeliverability('email', data.versionId, stepDisplayId));
    dispatch(loadWordRestrictions('email', data.versionId));
  } catch (error) {
    if (error?.response?.status === 404) {
      // No switch content for this step
      dispatch({ type: LOAD_CONTENT_FINISHED, payload: null });
    }
  }
};

export const getUserTemplateContent = (userTemplateId) => async dispatch => {
  dispatch({ type: LOAD_CONTENT_STARTED });
  try {
    const { data } = await axios.get(`${switchBasePath}/content/user-template/${userTemplateId}`);
    dispatch({ type: LOAD_CONTENT_FINISHED, payload: data });
    dispatch(computeDeliverability('user-template', data.userTemplateId, data.displayId));
    dispatch(loadWordRestrictions('user-template', data.userTemplateId));
  } catch (error) {
    if (error?.response?.status === 404) {
      // No switch content for this step
      dispatch({ type: LOAD_CONTENT_FINISHED, payload: null });
    }
  }
}

export const getWebsiteContent = displayId => async dispatch => {
  dispatch({ type: LOAD_CONTENT_STARTED });
  try {
    const { data } = await axios.get(`${switchBasePath}/content/website/${displayId}`);
    dispatch({ type: LOAD_CONTENT_FINISHED, payload: data });
  } catch (error) {
    if (error?.response?.status === 404) {
      // No switch content for this page
      dispatch({ type: LOAD_CONTENT_FINISHED, payload: null });
    }
  }
};

export const saveEmailContent = (type, stepDisplayId, data, eventNumber) => async (dispatch, getState) => {
  dispatch({ type: SAVE_CONTENT_STARTED });
  const { data: response } = await axios.post(`${switchBasePath}/content/email`, {
    stepDisplayId,
    stepType: type,
    eventNumber: eventNumber || null,
    data,
  });
  dispatch({ type: SAVE_CONTENT_FINISHED, payload: response });
  dispatch(computeDeliverability('email', getState().switch.content.versionId, stepDisplayId));
  return response;
};

export const saveEmailContentAttachments =
  (attachments, stepDisplayId, stepType) => async dispatch => {
    dispatch({ type: SAVE_ATTACHMENTS_STARTED });
    for (const attachment of attachments) {
      const { id: variableId, file } = attachment;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const data = await new Promise(resolve => {
        reader.onloadend = async () => {
          return resolve(reader.result.split(',')[1]);
        };
      });
      const res = await axios.post(
        `${switchBasePath}/content/email/${stepType}/${stepDisplayId}/attachment`,
        {
          data,
          variableId,
          contentType: file.type,
          fileName: file.name,
          attachmentType: 2, // program
        },
      );
      attachment.fileName = res.data;
    }
    dispatch({ type: SAVE_ATTACHMENTS_FINISHED });
    return attachments;
  };

export const saveWebsitePageContent = (displayId, data) => async dispatch => {
  dispatch({ type: SAVE_CONTENT_STARTED });
  const { data: response } = await axios.post(`${switchBasePath}/content/website`, {
    displayId,
    data,
  });
  dispatch({ type: SAVE_CONTENT_FINISHED, payload: response });
  return response;
};

export const saveWebsitePageContentAttachments = (attachments, displayId) => async dispatch => {
  dispatch({ type: SAVE_ATTACHMENTS_STARTED });
  for (const attachment of attachments) {
    const { id: variableId, file } = attachment;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const data = await new Promise(resolve => {
      reader.onloadend = async () => {
        return resolve(reader.result.split(',')[1]);
      };
    });
    const res = await axios.post(`${switchBasePath}/content/website/${displayId}/attachment`, {
      data,
      variableId,
      contentType: file.type,
      fileName: file.name,
    });
    attachment.fileName = res.data;
  }
  dispatch({ type: SAVE_ATTACHMENTS_FINISHED });
  return attachments;
};

export const initEmailContent =
  (type, stepDisplayId, templateType, templateId, eventNumber = null) =>
    async dispatch => {
      dispatch({ type: LOAD_CONTENT_STARTED });
      const { data } = await axios.post(
        `${switchBasePath}/content/email/${type}/${stepDisplayId}`,
        {
          eventNumber,
          templateType,
          templateId,
        },
      );
      dispatch(computeDeliverability('email', data.versionId, stepDisplayId));
      dispatch(loadWordRestrictions('email', data.versionId));
      dispatch({ type: LOAD_CONTENT_FINISHED, payload: data });
    };

export const initTags = () => async dispatch => {
  dispatch({ type: LOAD_TAGS_LIST_STARTED });
  try {
    const { data } = await axios.get(`${switchBasePath}/templates/tags/`);
    dispatch({ type: LOAD_TAGS_LIST_FINISHED, payload: data });
  } catch (error) {
    if (error?.response?.status === 404) {
      dispatch({ type: LOAD_TAGS_LIST_FINISHED, payload: null });
    }
  }
};

export const switchEmailApprovalStatus = (stepType, stepDisplayId, status) => async dispatch => {
  dispatch({ type: SET_CONTENT_APPROVAL_STATUS_STARTED });
  const { data } = await axios.put(
    `${switchBasePath}/content/email/${stepType}/${stepDisplayId}/status`,
    {
      approval: status,
    },
  );
  dispatch({
    type: SET_CONTENT_APPROVAL_STATUS_FINISHED,
    payload: { pendingApproval: data.pendingApproval, version: data.version },
  });
};

export const switchEmailFragmentStatus = (stepType, stepDisplayId, status) => async dispatch => {
  dispatch({ type: SET_CONTENT_FRAGMENT_STATUS_STARTED });
  await axios.patch(
    `${switchBasePath}/content/email/${stepType}/${stepDisplayId}/fragment`,
    {
      addFragment: status,
    },
  );
  dispatch({ type: SET_CONTENT_FRAGMENT_STATUS_FINISHED });
};

export const initNewAnnotation = annotation => dispatch => {
  dispatch({ type: INIT_NEW_ANNOTATION, payload: annotation });
};

export const saveNewEmailAnnotation = (annotation, type, stepDisplayId) => async dispatch => {
  dispatch({ type: SAVE_NEW_ANNOTATION_STARTED });
  try {
    const { content, selector, tags, recipients } = annotation;
    const { data } = await axios.post(
      `${switchBasePath}/content/email/${type}/${stepDisplayId}/annotations`,
      {
        content,
        selector,
        tags,
        recipients
      },
    );
    dispatch({ type: SAVE_NEW_ANNOTATION_FINISHED, payload: data });
  } catch (error) {
    if (error?.response?.status === 404) {
      dispatch({ type: SAVE_NEW_ANNOTATION_FINISHED, payload: null });
    }
  }
};

export const saveNewPageAnnotation = (annotation, stepDisplayId) => async dispatch => {
  dispatch({ type: SAVE_NEW_ANNOTATION_STARTED });
  try {
    const { content, selector, tags, recipients } = annotation;
    const { data } = await axios.post(
      `${switchBasePath}/content/website/${stepDisplayId}/annotations`,
      {
        content,
        selector,
        tags,
        recipients
      },
    );
    dispatch({ type: SAVE_NEW_ANNOTATION_FINISHED, payload: data });
  } catch (error) {
    if (error?.response?.status === 404) {
      dispatch({ type: SAVE_NEW_ANNOTATION_FINISHED, payload: null });
    }
  }
};

export const cancelNewAnnotation = () => dispatch => {
  dispatch({ type: CANCEL_NEW_ANNOTATION });
};

export const updateAnnotation = (annotationId, newData, type) => async dispatch => {
  dispatch({ type: UPDATE_ANNOTATION_STARTED });
  try {
    let updatedData;
    switch (type) {
      case 'markAsDone':
        const { done } = newData;
        const { data } = await axios.put(`${switchBasePath}/annotations/${annotationId}/status`, {
          done: done,
        });
        updatedData = { done: data.done };
        break;
      case 'newComment':
        const { content, recipients } = newData;
        const { data: commentData } = await axios.post(
          `${switchBasePath}/annotations/${annotationId}/comment`,
          {
            content: content,
            recipients: recipients
          },
        );
        updatedData = { comment: [...commentData.comments] };
        break;
      default:
    }
    dispatch({ type: UPDATE_ANNOTATION_FINISHED, payload: { annotationId, ...updatedData } });
  } catch (error) {
    if (error?.response?.status === 404) {
      dispatch({ type: UPDATE_ANNOTATION_FINISHED, payload: null });
    }
  }
};

export const deleteAnnotation = annotationId => async dispatch => {
  dispatch({ type: DELETE_ANNOTATION_STARTED });
  try {
    const { data } = await axios.delete(`${switchBasePath}/annotations/${annotationId}`);
    dispatch({ type: DELETE_ANNOTATION_FINISHED, payload: data });
  } catch (error) {
    if (error?.response?.status === 404) {
      dispatch({ type: DELETE_ANNOTATION_FINISHED, payload: null });
    }
  }
};

export const loadEmailAnnotationsList = (type, stepDisplayId, getAll) => async dispatch => {
  dispatch({ type: LOAD_ANNOTATIONS_LIST_STARTED });
  try {
    const { data } = await axios.get(
      `${switchBasePath}/content/email/${type}/${stepDisplayId}/annotations/${getAll}`,
    );
    dispatch({ type: LOAD_ANNOTATIONS_LIST_FINISHED, payload: data });
  } catch (error) {
    if (error?.response?.status === 404) {
      dispatch({ type: LOAD_ANNOTATIONS_LIST_FINISHED, payload: null });
    }
  }
};

export const loadPageAnnotationsList = stepDisplayId => async dispatch => {
  dispatch({ type: LOAD_ANNOTATIONS_LIST_STARTED });
  try {
    const { data } = await axios.get(
      `${switchBasePath}/content/website/${stepDisplayId}/annotations`,
    );
    dispatch({ type: LOAD_ANNOTATIONS_LIST_FINISHED, payload: data });
  } catch (error) {
    if (error?.response?.status === 404) {
      dispatch({ type: LOAD_ANNOTATIONS_LIST_FINISHED, payload: null });
    }
  }
};

export const initAnnotationsTags = () => async dispatch => {
  dispatch({ type: LOAD_ANNOTATIONS_TAGS_LIST_STARTED });
  try {
    const { data } = await axios.get(`${switchBasePath}/annotations/tags/`);
    dispatch({ type: LOAD_ANNOTATIONS_TAGS_LIST_FINISHED, payload: data });
  } catch (error) {
    if (error?.response?.status === 404) {
      dispatch({ type: LOAD_ANNOTATIONS_TAGS_LIST_FINISHED, payload: null });
    }
  }
};

export const deleteEmailContent = (type, stepDisplayId) => async dispatch => {
  dispatch({ type: DELETE_CONTENT_STARTED });
  await axios.delete(`${switchBasePath}/content/email/${type}/${stepDisplayId}`);
  dispatch({ type: DELETE_CONTENT_FINISHED });
};

export const createFragment =
  (campaignDisplayId, stepDisplayId, eventNumber, templateId) => async dispatch => {
    dispatch({ type: CREATE_FRAGMENT_STARTED });
    try {
      const { data } = await axios.post(`${switchBasePath}/fragments`, {
        campaign: campaignDisplayId,
        step: stepDisplayId,
        event: eventNumber,
        template: templateId,
      });
      dispatch({ type: CREATE_FRAGMENT_FINISHED, payload: data });
    } catch (error) {
      console.error(error);
      const errorDetails = error.response.data;
      if (errorDetails.message) throw errorDetails;
    }
  };

export const setFragmentDisplayId =
  (fragmentDisplayId) => async dispatch => {
    dispatch({ type: CREATE_FRAGMENT_FINISHED, payload: { fragment: { displayId: fragmentDisplayId } } });
  };

export const deleteEmail = (stepType, stepDisplayId) => async dispatch => {
  dispatch({ type: CANCEL_EMAIL_STARTED });
  await axios.delete(`${switchBasePath}/${stepType}s/${stepDisplayId}`);
  dispatch({ type: CANCEL_EMAIL_FINISHED });
};

export const loadBriefRequestComments = stepDisplayId => async dispatch => {
  dispatch({ type: LOAD_BRIEF_REQUEST_COMMENTS_STARTED });
  const { data } = await axios.get(
    `${switchBasePath}/briefRequestComments/byPageDisplayId/${stepDisplayId}`,
  );
  dispatch({ type: LOAD_BRIEF_REQUEST_COMMENTS_FINISHED, payload: data });
};

export const addBriefRequestComment = (payload) => async dispatch => {
  dispatch({ type: ADD_BRIEF_REQUEST_COMMENT_STARTED });
  const { data } = await axios.post(
    `${switchBasePath}/briefRequestComments/byPageDisplayId/${payload.displayId}`,
    { content: payload.content, attachment: payload.attachment, recipients: payload.recipients },
  );
  dispatch({ type: ADD_BRIEF_REQUEST_COMMENT_FINISHED, payload: data });
};

export const loadBriefRequest = stepDisplayId => async dispatch => {
  dispatch({ type: LOAD_BRIEF_REQUEST_STARTED });
  try {
    const { data } = await axios.get(
      `${switchBasePath}/briefRequests/byPageDisplayId/${stepDisplayId}`,
    );
    dispatch({ type: LOAD_BRIEF_REQUEST_FINISHED, payload: data });
  } catch (error) {
    if (error?.response?.status === 404) {
      dispatch({ type: LOAD_BRIEF_REQUEST_FINISHED, payload: null });
    }
  }
};

export const updateLoadedBriefRequest = briefRequest => {
  return { type: HANDLE_UPDATED_BRIEF_REQUEST, payload: briefRequest };
}

export const addAdditionalContact = (stepDisplayId, contact) => async dispatch => {
  dispatch({ type: ADD_FRAGMENT_ADDITIONAL_CONTACT_STARTED });
  try {
    await axios.post(
      `${fragments}/${stepDisplayId}/additional-contact`,
      contact,
    );
    dispatch({ type: ADD_FRAGMENT_ADDITIONAL_CONTACT_FINISHED, payload: contact });
  } catch (error) {
    console.error(error);
    dispatch({ type: ADD_FRAGMENT_ADDITIONAL_CONTACT_FINISHED });
  }
};

export const removeAdditionalContact = (stepDisplayId, contactId) => async dispatch => {
  dispatch({ type: REMOVE_FRAGMENT_ADDITIONAL_CONTACT_STARTED });
  try {
    await axios.delete(`${fragments}/${stepDisplayId}/additional-contact/${contactId}`,);
    dispatch({ type: REMOVE_FRAGMENT_ADDITIONAL_CONTACT_FINISHED, payload: contactId });
  } catch (error) {
    console.error(error);
    dispatch({ type: REMOVE_FRAGMENT_ADDITIONAL_CONTACT_FINISHED });
  }
};

export const saveNewUserTemplate = (stepDisplayId, stepType, name, data) => async dispatch => {
  dispatch({ type: SAVE_USER_TEMPLATE_STARTED });
  try {
    await axios.post(
      `${switchBasePath}/templates/user/${stepType}/${stepDisplayId}`,
      {
        name,
        data,
      },
    )
    dispatch({ type: SAVE_USER_TEMPLATE_FINISHED });
  } catch (error) {
    console.error(error);
    dispatch({ type: SAVE_USER_TEMPLATE_FINISHED });
  }
}

export const saveExistingUserTemplate = (templateId, data) => async (dispatch, getState) => {
  dispatch({ type: SAVE_USER_TEMPLATE_STARTED });
  try {
    await axios.put(
      `${switchBasePath}/templates/user/${templateId}`,
      {
        data,
      },
    )
    dispatch({ type: SAVE_USER_TEMPLATE_FINISHED });
    dispatch(computeDeliverability('user-template', templateId, getState().switch.content.displayId));
  } catch (error) {
    console.error(error);
    dispatch({ type: SAVE_USER_TEMPLATE_FINISHED });
  }
}

export const loadBestPractices = (language) => async dispatch => {
  dispatch({ type: LOAD_BEST_PRACTICES_STARTED });
  try {
    const { data } = await axios.get(
      `${switchBasePath}/deliverability/best-practices${language ? `?language=${encodeURI(language)}` : ''}`
    );
    dispatch({ type: LOAD_BEST_PRACTICES_FINISHED, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: LOAD_BEST_PRACTICES_FINISHED, payload: [] });
  }
}

export const loadWordRestrictions = (type, contentId) => async dispatch => {
  dispatch({ type: LOAD_WORD_RESTRICTIONS_STARTED });
  try {
    const { data } = await axios.get(
      `${switchBasePath}/deliverability/word-restrictions/${type}/${contentId}`
    );
    dispatch({ type: LOAD_WORD_RESTRICTIONS_FINISHED, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: LOAD_WORD_RESTRICTIONS_FINISHED, payload: [] });
  }
}

export const computeDeliverability = (type, contentId, displayId) => async dispatch => {
  dispatch({ type: LOAD_DELIVERABILITY_STARTED });
  try {
    const { data } = await axios.get(
      `${switchBasePath}/deliverability/scores/${type}/${contentId}/${displayId}`
    );
    dispatch({ type: LOAD_DELIVERABILITY_FINISHED, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: LOAD_DELIVERABILITY_FINISHED, payload: null });
  }
}
