import { State } from '../default';

const commonState = {
  pending: true,
  error: false,
  fullfilled: false
}

export const initialState = {
  step: {
    data: null,
    ...commonState,
    admin: {
      ...commonState
    },
    retroplanning: {
      ...commonState
    },
    options: {
      ...commonState
    },
    cta: {
      ...commonState
    },
    fragments: {
      ...commonState
    }
  },
  fragment: {
    data: null,
    ...commonState,
    pending: false
  },
  tags: {
    data: [],
    ...commonState
  },
  cta: {
    data: [],
    ...commonState
  },
  staff: {
    data: [],
    ...commonState
  },
  keyMessages: {
    data: [],
    ...commonState
  },
  surveys: {
    data: [],
    ...commonState
  },
  eventDetails: {
    data: null,
    ...commonState,
    pending: false
  },
  imrDetails: {
    data: null,
    ...commonState,
    pending: false
  },
  linkedFragments: {
    data: null,
    ...commonState,
    pending: false
  },
  emailTypes: {
    data: [],
    ...commonState
  },
  emailSubtypes: {
    data: [],
    ...commonState
  },
  users: {
    data: [],
    ...commonState
  },
  todoList: {
    data: [],
    ...commonState
  },
  senders: {
    data: [],
    ...commonState
  },
  reminderDraft: null,
  kpis: {
    data: null,
    ...commonState
  }
}

interface DataObjectState extends State { data: any }
interface DataArrayState extends State { data: any[] }

interface StepState extends DataObjectState { admin: State, retroplanning: State, options: State, cta: State, fragments: State }

export interface StepFormState {
  step: StepState,
  fragment: DataObjectState,
  tags: DataArrayState,
  cta: DataArrayState,
  staff: DataArrayState,
  keyMessages: DataArrayState,
  surveys: DataArrayState,
  eventDetails: DataObjectState,
  imrDetails: DataObjectState,
  linkedFragments: DataObjectState,
  emailTypes: DataArrayState,
  emailSubtypes: DataArrayState,
  users: DataArrayState,
  todoList: DataArrayState,
  senders: DataArrayState,
  reminderDraft: any,
  kpis: DataObjectState
}