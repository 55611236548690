import { applyMiddleware, combineReducers, createStore } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { authenticationReducer } from '../app/auth/authReducer';
import { authErrorReducer } from '../app/auth/authErrorReducer';
import { specialitiesReducer } from '../app/pages/specialities/state/reducer';
import { AdminListReducer } from './admin-list/reducer';
import { automatedEmailReducer } from './automated-email/reducer';
import { callCreateReducer } from './calls/reducer';
import { campaignCreateReducer } from './campaign-create/reducer';
import { campaignListingAllReducer } from './campaign-listing/reducer';
import { campaignProfileReducer } from './campaign-profile/reducer';
import { canvasBuilderReducer } from './customer-journey/reducer';
import { emailJourneyCreateReducer } from './email-journey/reducer';
import { eventReducer } from './events/reducer';
import { fragmentCreateReducer } from './fragment/reducer';
import { notificationReducer } from './notification/reducer';
import { smsCreateReducer } from './sms/reducer';
import { surveyCreateReducer, surveyTypesReducer } from './survey/reducer';
import { technicalBriefFormData } from './technical-brief-forms/reducer';
import { techBriefReducer } from './technical-brief/reducer';
import { websiteCreateReducer, websiteTypesReducer } from './website/reducer';
import { constantStateReducer } from './constant-state/reducer';
import { UserNotificationReducer } from './user-notification/reducer';
import { CalenderReducer } from './calender/reducer';
import TeamReducer from './team/reducer';
import GenericMailsReducer from './generic-mails/reducer';
import { campaignKpisReducer } from './campaign-kpis/reducers';
import { SwitchReducer } from './switch-editor/reducer';
import { projectTypesReducer } from './project/reducer';
import { postMailJourneyCreateReducer } from './post-mail-journey/reducer';
import { campaignStepCompletionReducer } from './campaign-step-completion/reducers';
import { profileSelectReducer } from './profile-select/reducers';

// new Redux Store items
import stepStatuses from '../new-store/step-statuses/reducer.ts';
import adminCampaignList from '../new-store/admin-nodes-list/reducer.ts';
import userCampaignList from '../new-store/user-campaign-list/reducer.ts';
import websiteReducer from '../new-store/websites/reducer.ts';
import websitePageReducer from '../new-store/website-pages/reducer.ts';
import websiteStatusReducer from '../new-store/website-status/reducer.ts';
import briefReducer from '../new-store/briefs/reducer.ts';
import briefRequestReducer from '../new-store/brief-requests/reducer.ts';
import therapeuticReducer from '../new-store/therapeutics/reducer.ts';
import productReducer from '../new-store/products/reducer.ts';
import tagReducer from '../new-store/tags/reducer.ts';
import templateReducer from '../new-store/templates/reducer.ts';
import briefCommentsReducer from '../new-store/brief-comments/reducer';
import agencyReducer from '../new-store/agencies/reducer';
import adminListViewReducer from '../new-store/admin-list-view/reducer';
import { CustomerRequestsReducer } from './customer-requests/reducer';
import targetingReducer from '../new-store/targeting/reducer';
import lightViewReducer from '../new-store/light-view/reducer';
import featuresReducer from '../new-store/features/reducer';
import cloneCampaignReducer from '../new-store/clone-campaign/reducer';
import stepFormReducer from '../new-store/step-forms/reducer';

const persistConfig = {
  key: 'orchestra',
  storage,
  whitelist: ['authentication'],
};

const reducers = combineReducers({
  adminCampaignList,
  customerJourneyBuilder: canvasBuilderReducer,
  campaignCreate: campaignCreateReducer,
  campaignListingAll: campaignListingAllReducer,
  emailJourneyCreate: emailJourneyCreateReducer,
  automatedEmailCreate: automatedEmailReducer,
  eventCreate: eventReducer,
  fragmentCreate: fragmentCreateReducer,
  smsCreate: smsCreateReducer,
  postMailJourneyCreate: postMailJourneyCreateReducer,
  surveyCreate: surveyCreateReducer,
  websiteCreate: websiteCreateReducer,
  callCreate: callCreateReducer,
  notification: notificationReducer,
  specialities: specialitiesReducer,
  techBrief: techBriefReducer,
  campaignProfile: campaignProfileReducer,
  technicalBriefFormData: technicalBriefFormData,
  adminList: AdminListReducer,
  authentication: authenticationReducer,
  authError: authErrorReducer,
  constantState: constantStateReducer,
  UserNotifications: UserNotificationReducer,
  calenderView: CalenderReducer,
  team: TeamReducer,
  genericMails: GenericMailsReducer,
  campaignKpis: campaignKpisReducer,
  switch: SwitchReducer,
  websiteTypes: websiteTypesReducer,
  projectTypes: projectTypesReducer,
  surveyTypes: surveyTypesReducer,
  campaignStepCompletion: campaignStepCompletionReducer,
  stepStatuses,
  userCampaignList,
  websites: websiteReducer,
  websitePages: websitePageReducer,
  websiteStatus: websiteStatusReducer,
  briefs: briefReducer,
  briefRequests: briefRequestReducer,
  briefComments: briefCommentsReducer,
  therapeutics: therapeuticReducer,
  products: productReducer,
  tags: tagReducer,
  templates: templateReducer,
  profileSelect: profileSelectReducer,
  agencies: agencyReducer,
  adminListView: adminListViewReducer,
  customerRequests: CustomerRequestsReducer,
  targeting: targetingReducer,
  lightView: lightViewReducer,
  features: featuresReducer,
  cloneCampaign: cloneCampaignReducer,
  stepForm: stepFormReducer
});
const rootReducer = persistReducer(persistConfig, reducers);
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(reduxThunk)));

export default store;
