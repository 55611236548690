export const LOAD_TEMPLATES_LIST_STARTED = 'LOAD_TEMPLATE_LIST_STARTED';
export const LOAD_TEMPLATES_LIST_FINISHED = 'LOAD_TEMPLATE_LIST_FINISHED';

export const LOAD_TEMPLATES_FROM_LAST_EMAIL_STARTED = 'LOAD_TEMPLATES_FROM_LAST_EMAIL_STARTED';
export const LOAD_TEMPLATES_FROM_LAST_EMAIL_FINISHED = 'LOAD_TEMPLATES_FROM_LAST_EMAIL_FINISHED';

export const LOAD_TAGS_LIST_STARTED = 'LOAD_TAGS_LIST_STARTED';
export const LOAD_TAGS_LIST_FINISHED = 'LOAD_TAGS_LIST_FINISHED';

export const TEMPLATE_PREVIEW_STARTED = 'TEMPLATE_PREVIEW_STARTED';
export const TEMPLATE_PREVIEW_FINISHED = 'TEMPLATE_PREVIEW_FINISHED';

export const SAVE_CONTENT_STARTED = 'SAVE_CONTENT_STARTED';
export const SAVE_CONTENT_FINISHED = 'SAVE_CONTENT_FINISHED';

export const LOAD_CONTENT_STARTED = 'LOAD_CONTENT_STARTED';
export const LOAD_CONTENT_FINISHED = 'LOAD_CONTENT_FINISHED';

export const LOAD_DELIVERABILITY_STARTED = 'LOAD_DELIVERABILITY_STARTED';
export const LOAD_DELIVERABILITY_FINISHED = 'LOAD_DELIVERABILITY_FINISHED';

export const SET_CONTENT_APPROVAL_STATUS_STARTED = 'SET_CONTENT_APPROVA_STATUS_STARTED';
export const SET_CONTENT_APPROVAL_STATUS_FINISHED = 'SET_CONTENT_APPROVAL_STATUS_FINISHED';

export const SET_CONTENT_FRAGMENT_STATUS_STARTED = 'SET_CONTENT_FRAGMENT_STATUS_STARTED';
export const SET_CONTENT_FRAGMENT_STATUS_FINISHED = 'SET_CONTENT_FRAGMENT_STATUS_FINISHED';

export const INIT_NEW_ANNOTATION = 'INIT_NEW_ANNOTATION';
export const CANCEL_NEW_ANNOTATION = 'CANCEL_NEW_ANNOTATION';
export const SAVE_NEW_ANNOTATION_STARTED = 'SAVE_NEW_ANNOTATION_STARTED';
export const SAVE_NEW_ANNOTATION_FINISHED = 'SAVE_NEW_ANNOTATION_FINISHED';

export const UPDATE_ANNOTATION_STARTED = 'UPDATE_ANNOTATION_STARTED';
export const UPDATE_ANNOTATION_FINISHED = 'UPDATE_ANNOTATION_FINISHED';

export const LOAD_ANNOTATIONS_TAGS_LIST_STARTED = 'LOAD_ANNOTATIONS_TAGS_LIST_STARTED';
export const LOAD_ANNOTATIONS_TAGS_LIST_FINISHED = 'LOAD_ANNOTATIONS_TAGS_LIST_FINISHED';

export const LOAD_ANNOTATIONS_LIST_STARTED = 'LOAD_ANNOTATIONS_LIST_STARTED';
export const LOAD_ANNOTATIONS_LIST_FINISHED = 'LOAD_ANNOTATIONS_LIST_FINISHED';

export const DELETE_ANNOTATION_STARTED = 'DELETE_ANNOTATION_STARTED';
export const DELETE_ANNOTATION_FINISHED = 'DELETE_ANNOTATION_FINISHED';

export const DELETE_CONTENT_STARTED = 'DELETE_CONTENT_STARTED';
export const DELETE_CONTENT_FINISHED = 'DELETE_CONTENT_FINISHED';

export const CREATE_FRAGMENT_STARTED = 'CREATE_FRAGMENT_STARTED';
export const CREATE_FRAGMENT_FINISHED = 'CREATE_FRAGMENT_FINISHED';

export const SAVE_ATTACHMENTS_STARTED = 'SAVE_ATTACHMENTS_STARTED';
export const SAVE_ATTACHMENTS_FINISHED = 'SAVE_ATTACHMENTS_FINISHED';

export const CANCEL_EMAIL_STARTED = 'CANCEL_EMAIL_STARTED';
export const CANCEL_EMAIL_FINISHED = 'CANCEL_EMAIL_FINISHED';

export const LOAD_BRIEF_REQUEST_COMMENTS_STARTED = 'LOAD_BRIEF_REQUEST_COMMENTS_STARTED';
export const LOAD_BRIEF_REQUEST_COMMENTS_FINISHED = 'LOAD_BRIEF_REQUEST_COMMENTS_FINISHED';

export const ADD_BRIEF_REQUEST_COMMENT_STARTED = 'ADD_BRIEF_REQUEST_COMMENT_STARTED';
export const ADD_BRIEF_REQUEST_COMMENT_FINISHED = 'ADD_BRIEF_REQUEST_COMMENT_FINISHED';

export const LOAD_BRIEF_REQUEST_STARTED = 'LOAD_BRIEF_REQUEST_STARTED';
export const LOAD_BRIEF_REQUEST_FINISHED = 'LOAD_BRIEF_REQUEST_FINISHED';

export const HANDLE_UPDATED_BRIEF_REQUEST = 'HANDLE_UPDATED_BRIEF_REQUEST';

export const ADD_FRAGMENT_ADDITIONAL_CONTACT_STARTED = 'ADD_FRAGMENT_ADDITIONAL_CONTACT_STARTED';
export const ADD_FRAGMENT_ADDITIONAL_CONTACT_FINISHED = 'ADD_FRAGMENT_ADDITIONAL_CONTACT_FINISHED';

export const REMOVE_FRAGMENT_ADDITIONAL_CONTACT_STARTED = 'REMOVE_FRAGMENT_ADDITIONAL_CONTACT_STARTED';
export const REMOVE_FRAGMENT_ADDITIONAL_CONTACT_FINISHED = 'REMOVE_FRAGMENT_ADDITIONAL_CONTACT_FINISHED';

export const SAVE_USER_TEMPLATE_STARTED = 'SAVE_USER_TEMPLATE_STARTED';
export const SAVE_USER_TEMPLATE_FINISHED = 'SAVE_USER_TEMPLATE_FINISHED';
export const DELETE_USER_TEMPLATE_STARTED = 'DELETE_USER_TEMPLATE_STARTED';
export const DELETE_USER_TEMPLATE_FINISHED = 'DELETE_USER_TEMPLATE_FINISHED';

export const LOAD_BEST_PRACTICES_STARTED = 'LOAD_BEST_PRACTICES_STARTED';
export const LOAD_BEST_PRACTICES_FINISHED = 'LOAD_BEST_PRACTICES_FINISHED';

export const LOAD_WORD_RESTRICTIONS_STARTED = 'LOAD_WORD_RESTRICTIONS_STARTED';
export const LOAD_WORD_RESTRICTIONS_FINISHED = 'LOAD_WORD_RESTRICTIONS_FINISHED';