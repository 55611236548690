import React, { useMemo } from 'react';
import { Tab, Tabs } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { checkAccessControl } from '../access-control/AccessControl';
import { defaultHomePages } from '../../../access-control/helpers';

import styles from './MenuTabs.module.scss';
import { featureSelector } from '../../../new-store/features/selectors';

const MenuTabs = ({ value, homepage }) => {
  const { t } = useTranslation();
  const profile = useSelector(({ authentication }) => authentication.profile);
  const featureState = useSelector(featureSelector);

  return (
    <div className={styles.tabsContainer}>
      <Tabs
        value={value}
        className={styles.tabs}
        indicatorColor="primary"
        textColor="inherit"
        centered
      >
        {
          checkAccessControl(featureState, 'default_view') &&
          <Tab
            className={styles.links}
            label={t('title.planning')}
            component={Link}
            to="/campaign/my-list"
            value="planning"
          />
        }

        {
          checkAccessControl(featureState, 'content_production') &&
          <Tab
            className={styles.links}
            label={t('title.content-production')}
            component={Link}
            to='/content-production'
            value='content-production'
          />
        }

        {
          !profile.lightView && defaultHomePages.includes(homepage) && checkAccessControl(featureState, 'webpages') &&
          <Tab
            className={styles.links}
            label={t('title.briefs')}
            component={Link}
            to="/briefs"
            value="briefs"
          />
        }
      </Tabs>
    </div>
  );
};

export default MenuTabs;
